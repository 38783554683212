import { ReactElement, useEffect, useRef, useState } from 'react'
import { fetchMissionCAST, toggleMissionCast } from './MissionCASTSlice'
import { useMap } from 'react-leaflet'
import Spinner from '../Spinner/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import './MissionCAST.css'
import L, { LatLngExpression } from 'leaflet'
import { renderToString } from 'react-dom/server'
import { deselectMenuItem, deselectMenuItemById, selectedItemIds, selectedItems } from '../../menus/SideNavigation/SideNavigationSlice'
import ZoomableContent, { ZoomablePannablePopupContent } from '../ZoomableContent/ZoomableContent'
import ReactDOM from 'react-dom'
import { RootState } from '../../store'
import useErrorStatus from '../../hooks/UseErrorStatus'

export const MissionCAST = function (): ReactElement {
  const [content, setContent] = useState<string>(
    renderToString(
      Spinner({ message: 'Your custom analytics is being generated' })
    )
  )
  const showMissionCAST = useSelector(
    (state) => state.missioncast.showMissionCAST
  )
  const [popup, setPopup] = useState<L.Popup>()
  const location = useSelector((state) => state.user.defaultLocation)
  const dispatch = useDispatch()
  const map = useMap()
  const errorStatus = useErrorStatus()

  let ux = null,
    uy = null
  let currentBox

  useEffect(() => {
    // ;(async () => {
    //   const data = await dispatch(
    //     fetchMissionCAST({
    //       lat: location.latitude,
    //       long: location.longitude,
    //     })
    //   )
    //   setContent(data.payload.data)
    // })()
    dispatch(fetchMissionCAST({
      lat: location.latitude,
      long: location.longitude,
    }))
    .unwrap()
    .then((response) => {
      // Assuming the response is the data you want to set
      setContent(response);
    })
    .catch((error) => {
      // Handle error here, such as setting an error state or logging
      errorStatus.addMessage("MissionCAST: " + error.message, 400, 'error', true)
      map.closePopup() // close the popup otherwise it will stay open and look like it's loading
      // console.error('Failed to fetch MissionCAST data:', error);
    });
  }, [])

  const createMissionCastRender = () => {
    return (
      <div id="missionCast" className="bg-white p-2 overflow-visible">
        {<div dangerouslySetInnerHTML={{ __html: content }} />}
      </div>
    )
  }

  useEffect(() => {
    const htmlRender = createMissionCastRender()
    const container = document.createElement('div')
    ReactDOM.render(<ZoomablePannablePopupContent content={htmlRender} />, container)
    popup?.setContent(container)
  }, [content])

  const makeDraggable = function (popup) {
    const pos = map.latLngToLayerPoint(popup?.getLatLng() as LatLngExpression)
    L.DomUtil.setPosition(popup._wrapper.parentNode, pos)
    const draggable = new L.Draggable(popup._container, popup._wrapper)
    draggable.enable()
  }
  const selectedItemId = useSelector((state:RootState)=> state.sideNav.selectedItemIds)
  useEffect(() => {
    if (showMissionCAST) {
      const newPopup = new L.Popup({
        minWidth: window.innerWidth * 0.8,
      })
        .setContent(content)
        .setLatLng([location.latitude, location.longitude])
        .openOn(map)
        .on('remove', () => {
          dispatch(toggleMissionCast(false))
           if(selectedItemId.includes("decision-missioncast")) {
              console.log("about to deselect decision missioncast")
              dispatch(deselectMenuItemById("decision-missioncast"))
            }
            dispatch(
              deselectMenuItem({
              id: 'decision-missioncast',
              label: 'MissionCast',
              link: '#',
              icon: null,
              active: false,
              subMenu: [],
            })
          )
        })
  
      setPopup(newPopup)
      makeDraggable(newPopup)
    } else {
// Close popup when showMissionCAST is false
      if (popup) {
        popup.removeFrom(map);
        setPopup(null);
      }
    }
  }, [showMissionCAST, location]);
  return <></>
}
