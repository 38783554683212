import { WMSTileLayer } from 'react-leaflet'
import { WMSURL } from '../../environment/apis.config'
import { getWMSTile } from '../../services/WMSWeatherService'
import Toast, { toastPosition, toastType } from '../Toast/Toast'
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface IWMSLayer {
  layerName: string
  legendUrl?: string
  opacity: number
  error?: string
}

interface ILegendProps {
  legendUrl: string
  dark?: boolean
}

const LegendToLayer = {
  'Wind Gust (Middle East)': 'MMME1-Wind_Gust',
  '80m Winds (Middle East)': 'MMME1-Wind_80',
  'Surface Winds (Middle East)': 'MMME1-Wind',
  'Temperature (Middle East)': 'MMME1-Temperature',
  'Visibility (Middle East)': 'MMME1-Visibility',
  'Cloud Ceiling (Middle East)': 'MMME1-Ceiling',
  'Wind Gust (CE)': 'MMCE1-Wind_Gust',
  '80m Winds (CE)': 'MMCE1-Wind_80',
  'Surface Winds (CE)': 'MMCE1-Wind',
  'Temperature (CE)': 'MMCE1-Temperature',
  'Visibility (CE)': 'MMCE1-Visibility',
  'Cloud Ceiling (CE)': 'MMCE1-Ceiling',
}

const Legend = (props: ILegendProps) => {
  let url = props.legendUrl

  if (props.dark === true) {
    url = props.legendUrl + '&style=dark'
  } else {
    url = props.legendUrl
  }

  return (
    <img
      src={url}
      alt="Legend"
      style={{
        zIndex: '801',
        position: 'absolute',
        top: '3rem',
        right: '2rem',
      }}
    />
  )
}

export const WMSLayer = function (props: IWMSLayer): ReactElement {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const [error, setError] = useState<string | null>(null)
  const [wmsUrl, setWMSUrl] = useState<string | null>(null)
  const [wmsLegendUri, setWMSLegendUri] = useState('')

  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=${
          LegendToLayer[props.layerName as keyof typeof LegendToLayer]
        }`

  const getWMSTileWithErrors = async (layerId: string) => {
    try {
      const data = await getWMSTile(layerId)

      if (typeof data === 'string') {
        // getWMSTile returns a string if there was an error
        throw new Error('Network error')
      }
      console.log(`${layerId} data = `, data)
      return data
    } catch (error) {
      if (error instanceof Error) {
        // Add additional information if needed
        // eslint-disable-next-line prettier/prettier
        error = error.message
      }
      throw error
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors(
          props.layerName
        )
        if (legend !== '') {
          setWMSLegendUri(legend)
        } else {
          setWMSLegendUri(legendUri)
        }
        setWMSUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
      } catch (error) {
        setError('Something went wrong. Please try again!')
        console.log(error)
      }
    })()
  }, [wmsUrl, darkMode])

  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {wmsUrl && (
        <WMSTileLayer
          url={wmsUrl}
          zIndex={800}
          opacity={props.opacity}
        ></WMSTileLayer>
      )}
      <Legend legendUrl={wmsLegendUri} dark={darkMode} />
    </>
  )
}
