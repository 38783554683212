import { useEffect, useState } from 'react'
import 'flowbite'
import {
  hideAlertSETTING,
  hideAlertSETTINGEdit,
  removeAlertForParticularHazard,
  showAlertSETTING,
  showAlertSETTINGEdit,
} from '../AlertingSlice/AlertingSlice'
import { useDispatch, useSelector } from 'react-redux'
import useAlertSetting from '../useAlertSetting'
import { elements } from '../../../interfaces/alertingelements'

type Props = {
  alertnumber?: number
  onDismissAlert: (dismissedAlerts: number) => void
  onClose: () => void
  dismissedAlerts: number
}
const AlertBox = (props: Props) => {
  const propertyElements = Object.entries(props.properties).map(
    ([key, value]) => ({
      key: key,
      value: value,
    })
  )
  const callSpecificAlert = useSelector(
    (state) => state.alert.callSpecificAlert
  )
  const eventList = useSelector((state) => state.alert.storingEventsToDisplay)
  const removeAlert = useSelector((state) => state.alert.removeAlert)
  const dispatch = useDispatch()
  const { dismissAlert, removingNotificationAlert } = useAlertSetting()
  const [eventName, seteventName] = useState(
    propertyElements.find((element) => element.key === 'liveEvent')
  )
  const [eventID, seteventID] = useState(
    propertyElements.find((element) => element.key === 'id')
  )
  const [formattedTime, setFormattedTime] = useState(
    propertyElements.find((element) => element.key === 'validAt')
  )
  const [showSettings, setShowSettings] = useState(false)
  const [alertDetails, setalertDetails] = useState(
    propertyElements.find((element) => element.key === 'triggerText')
  )
  const [hazardDetails, sethazardDetails] = useState(
    propertyElements.find((element) => element.key === 'hazardType')
  )
  const [thresholdDetails, setthresholDetails] = useState(
    propertyElements.find((element) => element.key === 'eventThreshold')
  )
  const [thresholdUnits, setthresholdUnits] = useState(
    propertyElements.find((element) => element.key === 'thresholdUnits')
  )
  const [comparison, setComparison] = useState(
    propertyElements.find((element) => element.key === 'comparison')
  )
  elements.forEach((element) => {
    if (element.value == hazardDetails?.value) {
      sethazardDetails(element.id)
    }
  })
  const handleShowSettingsClick = () => {
    setShowSettings(!showSettings)
    if (!showSettings) {
      dispatch(showAlertSETTING())
      dispatch(showAlertSETTINGEdit())
    } else if (showSettings) {
      dispatch(hideAlertSETTING())
      dispatch(hideAlertSETTINGEdit())
    }
  }
  const handleDismissAlertClick = () => {
    if (!callSpecificAlert) {
      dismissAlert(eventID?.value)
      removingNotificationAlert(eventID?.value)
      dispatch(hideAlertSETTING())
      dispatch(hideAlertSETTINGEdit())
    } else if (callSpecificAlert && !removeAlert.includes(eventID?.value)) {
      dismissAlert(eventID?.value)
      removingNotificationAlert(eventID?.value)
      dispatch(hideAlertSETTING())
      dispatch(hideAlertSETTINGEdit())
      dispatch(removeAlertForParticularHazard(eventID?.value))
    } else {
      dispatch(removeAlertForParticularHazard(eventID?.value))
    }

  }
  useEffect(()=>{
    let eveName=propertyElements.find((element) => element.key === 'triggerEvent')
    if((new Date(eventList[eveName.value]?.endTime)).toString() == "Invalid Date"
    ||((new Date(formattedTime.value) < new Date(eventList[eveName.value]?.endTime))))
    {
      let tempTime = (new Date(formattedTime.value)).toString()
      tempTime = tempTime.replace(/ GMT-[\d]+/, '');
      let temptime ={"value":tempTime}
      setFormattedTime(temptime)
    }
    else{
      let tempTime = new Date(eventList[eveName.value]?.endTime).toString()
      tempTime = tempTime.replace(/ GMT-[\d]+/, '');
      let temptime ={"value":tempTime}
      setFormattedTime(temptime)
    }
  },[])
  const alertBoxContent = (
    <>
      <div
        className="bg-black text-white flex items-center text-sm w-full"
        style={{ padding: '5px' }}
      >
        <span className="font text-lg">Alert</span>
      </div>
      <hr />
      <div className="p-2 text-center text-sm">
      <b>Valid At : </b> {formattedTime.value}
      </div>
      <div className="bg-yellow-700 text-black p-2 text-center text-sm">
        {alertDetails?.value}
      </div>
      <div className="flex justify-between mt-1">
        <button
          className="bg-green-500 text-white px-6 py-1 text-center mx-21 my-2 mt-4 ml-20 rounded-md text-sm"
          onClick={handleDismissAlertClick}
        >
          Dismiss Alert
        </button>
      </div>
      <hr className="mt-0" />

      <button
        className="text-black text-sm px-20 py-2 text-center mt-0 rounded-md flex"
        onClick={handleShowSettingsClick}
      >
        {showSettings ? 'Hide Alert Settings' : 'Change Alert Settings'}
      </button>
      {
        thresholdDetails &&
        <div className="rounded-md p-2">
          <div
            className={`text-black text-sm px-2 py-1 text-center ml-4 mr-4 ${
              thresholdDetails?.value <= 30 ? 'bg-red-500' : 'bg-red-500'
            }`}
          >
            {hazardDetails} threshold is {comparison?.value} {' '} { parseFloat(thresholdDetails?.value).toFixed(2)}{' '}
            {thresholdUnits?.value}
          </div>
        </div>
      }
     
    </>
  )
  return <div className="w-50 h-78 border-gray-300">{alertBoxContent}</div>
}
export default AlertBox
