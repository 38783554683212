import React from 'react';

interface IConfirmDialogProps {
    message: string
    onConfirm: () => void
    onCancel: () => void
    isOpen: boolean
}

const ConfirmDialog = ({ isOpen, message, onConfirm, onCancel }:IConfirmDialogProps) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-dialog-backdrop fixed top-0 left-0 w-full h-full flex justify-center items-center" style={{backgroundColor:"rgba(0,0,0,0.5)"}}>
      <div className="confirm-dialog bg-white p-5 flex flex-col gap-2.5">
        <p>{message}</p>
        <div className='flex flex-row gap-2.5 justify-center'>
            <button className='py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-40 h-10' onClick={onConfirm}>Confirm</button>
            <button className='py-2.5 my-0 mt-4 text-center text-white bg-red-500 rounded-md w-40 h-10' onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;