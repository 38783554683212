import React from 'react';
import jsPDF from 'jspdf';
import { toPng } from 'html-to-image';

/**
 * PdfGenerator utility automatically generates a PDF from a given HTML element
 * 
 * @param {HTMLElement} element A HTML element to be exported to PDF
 * @param {string} fileName The name of the file to be exported
 * @returns 
 */

const PdfGenerator = ({element, fileName = 'Export.pdf' }) => {

  const generatePDF = () => {
    
    if (!element) {
      console.error('No valid element passed to PdfGenerator');
      return;
    }

    toPng(element)
      .then((imgData) => {
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);

        // Define the margins
        const margin = 10; // 10 mm margin
        const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // Ensure the image height fits within the page height (considering the margins)
        const pageHeight = pdf.internal.pageSize.getHeight() - margin * 2;
        const heightAdjustmentRatio = pageHeight / pdfHeight;

        let adjustedWidth = pdfWidth;
        let adjustedHeight = pdfHeight;

        if (pdfHeight > pageHeight) {
          adjustedHeight = pageHeight;
          adjustedWidth = pdfWidth * heightAdjustmentRatio;
        }

        // Add the image with margins
        pdf.addImage(imgData, 'PNG', margin, margin, adjustedWidth, adjustedHeight);
        pdf.save(fileName);
      })
      .catch((err) => {
        console.error('Failed to generate PDF', err);
      });
  };

  return (
    <button className="bg-gray-300 mt-5 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={generatePDF}>Export to PDF</button>
  );
};

export default PdfGenerator;
