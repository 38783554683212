/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eslint-comments/no-duplicate-disable */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useRef } from 'react'
import userIcon from '../../assets/user.svg'
import chevron from '../../assets/chevron.svg'
// import savedView from '../../assets/saved-view.svg'
// import switchAccount from '../../assets/switch-account.svg'
// import securitySettings from '../../assets/security-settings.svg'
// import notification from '../../assets/notification.svg'
// import subscriptionDetails from '../../assets/subscription-details.svg'
import weatherSensorDetails from '../../assets/weather-sensor-details.svg'
import logoutIcon from '../../assets/logout.svg'
import thresholds from '../../assets/thresholds.svg'
import faq from '../../assets/faq.svg'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, resetReduxStore } from '../../store'
import { TWS_V15_URL } from '../../environment/apis.config'
import { userLogoutAction } from '../../Components/Users/UserSlice'
import { initSideMenu } from '../SideNavigation/SideNavigationSlice'
// import { logout as logoutAction } from '../../Components/LoginPage/loginSlice'
// import { Link } from 'react-router-dom'

export default function ProfileMenu() {
  const [show, setShow] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const firstname = useSelector((state: RootState) => state.user.firstname)
  const lastname = useSelector((state: RootState) => state.user.lastname)
  const signInRef = useRef(null)
  const signUpRef = useRef(null)
  const itemSelected = () => {
    setShow(false)
  }
  const dispatch = useDispatch()

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('defaultLocation')
    localStorage.removeItem('userId')
    localStorage.setItem('logout-event', 'logout'+Math.random())
    dispatch(userLogoutAction())
    dispatch(initSideMenu())
    setAuthenticated(false)
    setShow(false)
  }

  // const token = sessionStorage.getItem('token')
  const token = localStorage.getItem('token')

  if (token && authenticated === false) {
    setAuthenticated(true)
  }

  const nonAuthenticatedMenu = (
    <div className="absolute block bg-white p-4 top-12 right-1 w-72 z-[99999]">
      <div className="profile-dropdown-content">
        <Link to="/login">
          <div
            ref={signInRef}
            className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer"
            onClick={itemSelected}
          >
            Sign In
          </div>
        </Link>
        <div
          ref={signUpRef}
          className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer"
          onClick={itemSelected}
        >
          Sign Up
        </div>
      </div>
    </div>
  )
  const authenticatedMenu = (
    <div className="absolute block bg-white p-4 top-16 right-1 w-72 z-[99999] shadow-lg">
      <div className="capitalize">
        {firstname} {lastname}
      </div>
      <div>
        <hr />
      </div>
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={savedView}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Saved View</p>
      </div> */}
      <Link to="/userprofile" onClick={itemSelected}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={userIcon}
            alt=""
            className="mr-4 h-6 align-middle float-left invert"
            style={{ height: '18px' }}
          />
          <p>Your Profile</p>
        </div>
      </Link>
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={switchAccount}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Switch Account</p>
      </div> */}
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={securitySettings}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Security Settings</p>
      </div> */}
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={notification}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Notification</p>
      </div> */}
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={subscriptionDetails}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Subscription Details</p>
      </div> */}
      <Link to="/sensordetails" onClick={itemSelected}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={weatherSensorDetails}
            alt=""
            className="mr-4 h-6 align-middle float-left"
            style={{ height: '18px' }}
          />
          <p>Weather Sensor Details</p>
        </div>
      </Link>
      <Link to="/customthresholds" onClick={itemSelected}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={thresholds}
            alt=""
            className="mr-4 h-6 align-middle float-left"
            style={{ height: '18px' }}
          />
          <p>Custom Thresholds</p>
        </div>
      </Link>
      <Link to="/faq" onClick={itemSelected}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={faq}
            alt=""
            className="mr-4 h-6 align-middle float-left"
            style={{ height: '18px' }}
          />
          <p>Frequently Asked Questions</p>
        </div>
      </Link>
      <div>
        <hr />
      </div>
      <Link to="." className="profile-menu-dropdown-item" onClick={logout}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={logoutIcon}
            alt=""
            className="mr-4 h-6 align-middle float-left"
            style={{ height: '18px' }}
          />
          <p>Logout</p>
        </div>
      </Link>
    </div>
  )

  const handleMenuToggle = () => {
    setShow(!show)
  }

  const dropdownMenu = authenticated ? authenticatedMenu : nonAuthenticatedMenu
  return (
    <>
      <div
        className="self-center border-l border-black border-solid px-4 w-64 cursor-pointer text-white"
        onClick={handleMenuToggle}
      >
        <div>
          <img
            className="mr-4 h-6 align-middle float-left"
            src={userIcon}
            alt=""
          />
        </div>
        <span className="capitalize">{firstname}</span>
        <img
          className="invert float-right mt-2 mr-4 mb-0 ml-4"
          src={chevron}
          alt=""
          style={{ filter: 'brightness(0) invert(1)' }}
        />
      </div>
      {show ? dropdownMenu : <></>}
    </>
  )
}
