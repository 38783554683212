import {
  Popup,
  CircleMarker,
  Polygon,
  Polyline,
  Marker,
  Circle,
} from 'react-leaflet'
import { ReactElement, useEffect, useState } from 'react'
import MapPopupTable from '../MapPopupTable/MapPopupTable'
import '../CustomMapMarker/CustomMapMarker.css'
import NetworkData from '../../interfaces/networkData'
import AlertBox from '../Alerting/AlertingBox/AlertingBox'
import { useSelector } from 'react-redux'
import useAlertSetting from '../Alerting/useAlertSetting'
import { elements } from '../../interfaces/alertingelements'
import EventBox from '../Alerting/AlertingBox/EventBox'
import PolygonPopupTable from '../MapPopupTable/PolygonPopupTable'
import { RootState } from '../../store'
import starRed from '../../assets/starred.svg'
import starGreen from '../../assets/stargreen.svg'
import starYellow from '../../assets/staryellow.svg'
import triangle from '../../assets/triangle.svg'
import darkmodeTriangle from '../../assets/darkmode-triangle.svg'
import circle from '../../assets/circle-icon.svg'
import darkmodeCircle from '../../assets/darkmode-circle-icon.svg'
import L from 'leaflet'

interface CustomMapMarkerProps {
  index: number
  feature: NetworkData
  tableView: boolean
  popupContent?: React.ReactElement | null
  networkData: NetworkData[]
}

const CustomMapMarker = ({
  index,
  feature,
  tableView,
  popupContent,
  networkData,
}: CustomMapMarkerProps) => {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const { openAlertSetting, closeAlertSetting } = useAlertSetting()
  const removeAlert = useSelector((state) => state.alert.removeAlert)
  const [inPolygons, setInPolygons] = useState()

  const removeAlertForParticularHazard = useSelector(
    (state) => state.alert.removeAlertForParticularHazard
  )
  const alertNotificationCalled = useSelector(
    (state) => state.alert.callNetworkAdapter
  )
  const specificAlertCalled = useSelector(
    (state) => state.alert.callSpecificAlert
  )
  const addAlert = useSelector((state) => state.alert.sideNavAlerts)
  const showAlertNotificat = useSelector(
    (state) => state?.alert?.showAlertNotification
  )
  const showEventList = useSelector((state) => state.alert.showEventList)
  const showEventsIfAlertNotPresent = useSelector(
    (state) => state.alert.showEventsIfAlertNotPresent
  )
  const handlePopupClick = (properties) => {
    openAlertSetting(properties)
    closeAlertSetting()
  }

  const isPointInPolygon = (point, polygon) => {
    const x = point.lng,
      y = point.lat // Correcting the order here to [longitude, latitude]
    let inside = false

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xj = polygon[j][0],
        yj = polygon[j][1] // x is longitude, y is latitude
      const xi = polygon[i][0],
        yi = polygon[i][1]

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) {
        inside = !inside
      }
    }
    return inside
  }

  const isPointInPoly = (feature, pt: LatLng) => {
    const poly = feature.coordinates ? feature.coordinates[0] : []
    const y = pt.lat,
      x = pt.lng

    let inside = false
    for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
      const xi = poly[i][1]
      const yi = poly[i][0]
      const xj = poly[j][1]
      const yj = poly[j][0]
      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi

      if (intersect) inside = !inside
    }
    return inside
  }

  const handlePolygonPopupClick = (properties, feature) => {
    // feature = poly
    const poly = feature //feature.map((p) => {

    const ret = []
    feature.forEach((element) => {
      // test the point for each polygon

      if (element !== undefined) {
        const pointInPoly = isPointInPoly(element, properties)

        if (pointInPoly === true) {
          // if point is in polygon, add the AIRMET data to the popup
          ret.push(element)
        }
      }
    })
    setInPolygons(ret)
  }

  let markerComponent
  if (feature.properties === undefined) {
    console.log('feature undefined', feature)
  }
  if (
    Object.keys(feature.properties).includes('Raw TAF') ||
    feature.properties['Report Type']?.value == 'METAR' ||
    feature.properties?.data == 'AIREP' || 
    feature.properties.airepType
  ) {
    const iconurl = markerMetarTAFMarker(feature, darkMode)
    const mapIcon = new L.Icon({
      iconUrl: iconurl,
      iconSize: [24, 24],
      iconAnchor: [12, 12],
      popupAnchor: [0, -12],
      className: darkMode === true ? 'brightness-1000 contrast-10000' : '',
    })

    markerComponent = (
      <Marker
        key={index}
        position={[feature.latitude, feature.longitude]}
        icon={mapIcon}
      >
        <Popup className="taf-popup">
          {tableView ? (
            <MapPopupTable properties={feature.properties} />
          ) : (
            { popupContent }
          )}
        </Popup>
      </Marker>
    )
  } else if (feature.properties?.airSigmetType == 'AIRMET') {
    let polyonColor = 'black'
    if (feature.color == 'TURB') {
      polyonColor = 'yellow'
    } else if (feature.color == 'ICE') {
      polyonColor = 'blue'
    } else if (feature.color == 'IFR') {
      polyonColor = 'LightGreen'
    } else if (feature.color == 'MTN OBSCN') {
      polyonColor = 'Green'
    }

    markerComponent = (
      <Polygon
        key={index}
        color={polyonColor}
        fill={true}
        fillOpacity={0.3}
        weight={Number(3)}
        dashArray="3"
        positions={[feature.coordinates]}
        eventHandlers={{
          click: (e) => {
            // 'e.latlng' holds the latitude and longitude of the click position
            handlePolygonPopupClick(e.latlng, networkData)
          },
        }}
      >
        <Popup className="airmet-popup">
          {tableView && (
            <AIRMETPopup
              features={inPolygons}
              icaoId={feature.properties.icaoId}
            ></AIRMETPopup>
          )}
        </Popup>
      </Polygon>
    )
  } else if (feature.properties?.data == 'SIGMET') {
    let polyonColor = 'black'
    if (feature.color == 'CONVECTIVE') {
      polyonColor = 'Red'
    } else if (feature.color == 'TURB') {
      polyonColor = 'Yellow'
    } else if (feature.color == 'ICE') {
      polyonColor = 'Blue'
    } else if (feature.color == 'IFR') {
      polyonColor = 'LightGreen'
    } else if (feature.color == 'MTN OBSCN') {
      polyonColor = 'Green'
    }
    markerComponent = (
      <Polygon
        key={index}
        color={polyonColor}
        fill={true}
        fillOpacity={0.3}
        weight={Number(3)}
        dashArray="3"
        positions={[feature.coordinates]}
      >
        <Popup className="airmet-popup">
          {tableView ? (
            <PolygonPopupTable networkData={networkData} feature={feature} />
          ) : (
            { popupContent }
          )}
        </Popup>
      </Polygon>
    )
  } else if (
    feature.properties?.StationID ||
    feature.properties?.SensorType?.value
  ) {
    markerComponent = (
      <CircleMarker
        key={index}
        center={[feature.latitude, feature.longitude]}
        radius={6}
        fill={true}
        fillOpacity={0.9}
        fillColor={markerColor(feature)}
        color="black"
        stroke={true}
        weight={1}
        className="text-black border-black border-opacity-100"
      >
        <Popup className="custom-popup">
          {tableView ? (
            <MapPopupTable properties={feature.properties} />
          ) : (
            { popupContent }
          )}
        </Popup>
      </CircleMarker>
    )
  } else if (
    feature.properties?.messageType &&
    (feature?.properties?.messageType == 'ALERT' ||
      feature?.properties?.messageType == 'CONTINUATION')
  ) {
    if (!specificAlertCalled) {
      if (!removeAlert?.includes(feature.properties?.id)) {
        if (
          !alertNotificationCalled ||
          (alertNotificationCalled &&
            showAlertNotificat?.includes(feature.properties?.id))
        ) {
          if (feature.type == 'Polygon') {
            markerComponent = (
              <>
                <Polygon
                  key={index}
                  fill={markerFillAlert(feature)}
                  fillOpacity={markerFillOpacity(feature)}
                  weight={Number(3)}
                  dashArray={markerDashAlert(feature)}
                  fillColor={markerColorAlert(feature)}
                  color={markerColorAlert(feature)}
                  positions={[feature.coordinates]}
                >
                  <Popup className="p-0 custom-popup">
                    <PolygonPopupTable
                      networkData={networkData}
                      popupContent={popupContent}
                      tableView={tableView}
                      feature={feature}
                    ></PolygonPopupTable>
                  </Popup>
                </Polygon>
              </>
            )
          } else if (feature.type == 'Point') {
            markerComponent = (
              <>
                <CircleMarker
                  key={index}
                  fill={true}
                  fillOpacity={markerFillOpacity(feature)}
                  weight={2.25}
                  dashArray={markerDashAlert(feature)}
                  fillColor={markerColorAlert(feature)}
                  color={markerColorAlert(feature)}
                  center={[feature.coordinates[1], feature.coordinates[0]]}
                  radius={18}
                >
                  <Popup className="p-0">
                    <div onClick={() => handlePopupClick(feature.properties)}>
                      {tableView ? (
                        <AlertBox properties={feature.properties} />
                      ) : (
                        <>{popupContent}</>
                      )}
                    </div>
                  </Popup>
                </CircleMarker>
              </>
            )
          } else {
            markerComponent = (
              <Polyline
                key={index}
                fill={markerFillAlert(feature)}
                fillOpacity={markerFillOpacity(feature)}
                weight={2.25}
                dashArray={markerDashAlert(feature)}
                fillColor={markerColorAlert(feature)}
                color={markerColorAlert(feature)}
                positions={[feature.coordinates]}
              >
                <Popup className="p-0 custom-popup">
                  <PolygonPopupTable
                    networkData={networkData}
                    popupContent={popupContent}
                    tableView={tableView}
                    feature={feature}
                  ></PolygonPopupTable>
                </Popup>
              </Polyline>
            )
          }
        } else {
          return null
        }
      } else {
        return null
      }
    } else {
      let matchedElement = null
      elements.forEach((element) => {
        if (element.value === feature?.properties?.hazardType) {
          matchedElement = element.id
          return
        }
      })
      if (
        addAlert?.includes(matchedElement) ||
        ((matchedElement == 'NWS Severe Thunderstorm Warning' ||
          matchedElement == 'NWS Tornado Warning') &&
          addAlert?.includes('NWS Alerts'))
      ) {
        if (!removeAlertForParticularHazard?.includes(feature.properties?.id)) {
          if (feature.type == 'Polygon') {
            markerComponent = (
              <>
                <Polygon
                  key={index}
                  fill={markerFillAlert(feature)}
                  fillOpacity={markerFillOpacity(feature)}
                  weight={Number(3)}
                  dashArray={markerDashAlert(feature)}
                  fillColor={markerColorAlert(feature)}
                  color={markerColorAlert(feature)}
                  positions={[feature.coordinates]}
                >
                  <Popup className="p-0 custom-popup">
                    <PolygonPopupTable
                      networkData={networkData}
                      popupContent={popupContent}
                      tableView={tableView}
                      feature={feature}
                    ></PolygonPopupTable>
                  </Popup>
                </Polygon>
              </>
            )
          } else if (feature.type == 'Point') {
            markerComponent = (
              <>
                <CircleMarker
                  key={index}
                  fill={true}
                  fillOpacity={0.3}
                  weight={2.25}
                  dashArray={markerDashAlert(feature)}
                  fillColor={markerColorAlert(feature)}
                  color={markerColorAlert(feature)}
                  center={[feature.coordinates[1], feature.coordinates[0]]}
                  radius={18}
                >
                  <Popup className="p-0">
                    <div onClick={() => handlePopupClick(feature.properties)}>
                      {tableView ? (
                        <AlertBox properties={feature.properties} />
                      ) : (
                        <>{popupContent}</>
                      )}
                    </div>
                  </Popup>
                </CircleMarker>
              </>
            )
          } else {
            markerComponent = (
              <Polyline
                key={index}
                fill={markerFillAlert(feature)}
                fillOpacity={0.3}
                weight={2.25}
                dashArray={markerDashAlert(feature)}
                fillColor={markerColorAlert(feature)}
                color={markerColorAlert(feature)}
                positions={[feature.coordinates]}
              >
                <Popup className="p-0 custom-popup">
                  <PolygonPopupTable
                    networkData={networkData}
                    popupContent={popupContent}
                    tableView={tableView}
                    feature={feature}
                  ></PolygonPopupTable>
                </Popup>
              </Polyline>
            )
          }
        } else {
          return null
        }
      } else {
        return null
      }
    }
  } else if (
    feature?.properties?.type &&
    feature?.properties?.type == 'Event'
  ) {
    if (showEventsIfAlertNotPresent || showEventList) {
      markerComponent = (
        <Polygon
          key={index}
          fill={false}
          weight={Number(3)}
          positions={[feature.bufferGeometry]}
          pathOptions={{ color: 'black' }}
        >
          <Popup className="text-lg">
            {tableView ? (
              <div className="overflow-hidden h-full">
                <EventBox properties={feature.properties} />
              </div>
            ) : (
              <>{popupContent}</>
            )}
          </Popup>
        </Polygon>
      )
    } else {
      return null
    }
  } else if (feature?.properties === "Aerox Data") {

    markerComponent = (
      <CircleMarker
        zIndex={2999999999}
        key={index}
        fill={true}
        fillOpacity={0.3}
        weight={2.25}
        dashArray={'dot'}
        fillColor={'red'}
        color={'red'}
        center={[feature.latitude, feature.longitude]}
        radius={feature['Radius'] * 10}
        >
          <Popup className="text-lg">
          <div
            className="bg-black text-white flex items-center text-sm"
            style={{ padding: '5px' }}
          >
            <span className="font text-lg">{feature['Name']}</span>
          </div>
          <hr />
          <div className="p-2 text-center text-sm">
          Description: {feature['Description']}
          </div>
          <hr />
          <div className="p-2 text-center text-sm">
            NOTAM: {feature['NOTAM']}
          </div>
          </Popup>
        </CircleMarker>
    )
  }
   else {
    return null
  }
  return markerComponent
}

const metarColorMap = (feature: NetworkData) => {
  const properties = feature?.properties

  let windValue = properties['Wind Speed'].value,
    ceilingValue = properties['Ceiling'].value,
    temperatureValue = properties['Temperature'].value,
    visibilityValue = properties['Visibility'].value,
    windGustValue = properties['Wind Gust'].value

  if (ceilingValue === 'No Ceiling') {
    ceilingValue = 10000
  }
  if (temperatureValue === 'N/A') {
    temperatureValue = 21
  }
  if (windValue === 'N/A') {
    windValue = 4
  }

  if (visibilityValue === 'N/A' || !visibilityValue) {
    visibilityValue = 10
  }

  if (windGustValue === 'N/A' || windGustValue === 'NONE' || !windGustValue) {
    windGustValue = 13
  }

  if (
    windValue > 15 ||
    windValue < -3.9 ||
    temperatureValue > 35 ||
    temperatureValue < -4 ||
    ceilingValue <= 600 ||
    visibilityValue <= 3 ||
    windGustValue > 24
  ) {
    return 'red'
  }

  if (
    (windValue > 8 && windValue <= 15) ||
    (temperatureValue >= -3.9 && temperatureValue < 2.2) ||
    (temperatureValue >= 31.6 && temperatureValue <= 35) ||
    (ceilingValue < 1000 && ceilingValue > 600) ||
    (visibilityValue < 4 && visibilityValue > 3) ||
    (windGustValue >= 14 && windGustValue <= 24)
  ) {
    return 'yellow'
  }

  return 'green'
}

const markerColor = (feature: NetworkData) => {
  let Ceiling, visibility
  const CeilBool = feature?.properties?.Ceiling?.value ? true : false
  const VisBool = feature?.properties?.Visibility ? true : false
  let tempVal = feature?.properties?.Temperature?.value
  let windVal = feature?.properties?.WindSpeed?.value

  if (tempVal === 'NA') {
    // if the temperature is not available set it to 21.0 to force green
    tempVal = 21.0
  }
  if (windVal === 'NA') {
    // if the wind speed is not available set it to 4.0 to force green
    windVal = 4
  }
  if (CeilBool) {
    if (feature?.properties?.Ceiling.value >= 1000) Ceiling = 'Green'
    else if (
      feature?.properties?.Ceiling.value >= 500 &&
      feature?.properties?.Ceiling.value < 1000
    )
      Ceiling = 'Yellow'
    else if (feature?.properties?.Ceiling.value < 500) Ceiling = 'Red'
  }
  if (VisBool) {
    if (feature?.properties?.Visibility?.value >= 5) visibility = 'Green'
    else if (
      feature?.properties?.Visibility?.value >= 3 &&
      feature?.properties?.Visibility?.value < 5
    )
      visibility = 'Yellow'
    else if (feature?.properties?.Visibility?.value < 3) visibility = 'Red'
  }
  if (
    windVal > 15 ||
    windVal < -3.9 ||
    tempVal > 35 ||
    tempVal < -4 ||
    (CeilBool && Ceiling === 'Red') ||
    (VisBool && visibility === 'Red')
  ) {
    return 'red'
  } else if (
    (windVal > 8 && windVal <= 15) ||
    (tempVal >= -3.9 && tempVal < 2.2) ||
    (tempVal >= 31.6 && tempVal <= 35) ||
    (CeilBool && Ceiling === 'Yellow') ||
    (VisBool && visibility === 'Yellow')
  ) {
    return 'yellow'
  } else if (
    windVal <= 8 &&
    tempVal >= 2.2 &&
    tempVal < 31.6 &&
    CeilBool &&
    Ceiling === 'Green' &&
    VisBool &&
    visibility === 'Green'
  ) {
    return 'green'
  } else {
    return 'green'
  }
}
const markerColorAlert = (feature: NetworkData) => {
  let color = '#'
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      color += element?.color
      return
    }
  })
  return color
}
const markerFillAlert = (feature: NetworkData) => {
  let fill = false
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      fill = element?.fill
      return
    }
  })
  return fill
}
const markerFillOpacity = (feature: NetworkData) => {
  let opacity = 0.3
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      opacity = element?.opacity
      return
    }
  })
  return opacity
}
const markerDashAlert = (feature: NetworkData) => {
  let dash
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      if (element?.line === 'dashed') {
        dash = '10'
        return
      } else if (element?.line === 'dot') {
        dash = '3'
        return
      } else {
        dash = '0'
        return
      }
    }
  })
  return dash
}

const getRadius = (feature: NetworkData) => {
  const radiusRaw = feature?.properties?.products[0]?.parameters[0]?.distance
  const radiusUnit =
    feature?.properties?.products[0]?.parameters[0]?.distanceUnits
  const units = { miles: 1609.34, kilometers: 1000 }
  return units[radiusUnit] ? radiusRaw * units[radiusUnit] : radiusRaw
}
const markerMetarTAFMarker = (feature: NetworkData, darkmode: boolean) => {
  if (feature.properties['Report Type']?.value === 'METAR') {
    switch (metarColorMap(feature)) {
      case 'red':
        return starRed
      case 'yellow':
        return starYellow
      case 'green':
        return starGreen
      default:
        return starGreen
    }
  } else if (feature.properties?.Data == 'TAF') {
    return darkmode ? darkmodeCircle : circle
  } else {
    return darkmode ? darkmodeTriangle : triangle
  }
}

const AIRMETPopup = (props) => {
  const features = props.features
  const getColor = (hazard) => {
    switch (hazard) {
      case 'CONVECTIVE':
        return 'red'
      case 'TURB':
        return 'yellow'
      case 'ICE':
        return 'Blue'
      case 'IFR':
        return 'lightgreen'
      case 'MTN OBSCN':
        return 'green'
      default:
        return 'transparent'
    }
  }
  const [selectedAIRMET, setSelectedAIRMET] = useState<number | null>(null)
  const elements = []
  const [content, setContent] = useState(null)

  const toggleHazard = (i) => {
    if (selectedAIRMET === i) {
      setSelectedAIRMET(null)
    } else {
      setSelectedAIRMET(i)
    }
  }

  const items = features

  for (const item in items) {
    const currentItem = { ...items[item] }
    const icaoId = currentItem.properties.icaoId
    const airSigmetType = currentItem.properties.airSigmetType // save the data type
    const data = currentItem.properties.hazard // save the data type
    const rawAirSigmet = currentItem.properties.rawAirSigmet // save the raw airsigmet

    // convert the object to an array of key-value pairs
    const properties = Object.keys(currentItem.properties).map((key) => [
      key,
      currentItem.properties[key],
    ])
    properties.sort((a, b) => a[0].localeCompare(b[0])) //

    elements.push(
      <>
        <div className="flex flex-col w-full text-sm">
          <div
            className="flex w-full text-sm cursor-pointer"
            data-idx={item}
            onClick={() => toggleHazard(item)}
          >
            <div
              className="w-8 h-4 mb-3 mr-4 text-white text-center"
              style={{ backgroundColor: getColor(currentItem.color) }}
            >
              {/*'⨁'*/}
            </div>
            <div className="mr-1">AIRMET</div>
            <div className="mr-1">{currentItem.color}</div>
            <div className="mr-1">{props.icaoId}</div>
          </div>
          {selectedAIRMET === null ? (
            <></>
          ) : selectedAIRMET === item ? (
            <AirmetTable
              currentItem={currentItem}
              icaoId={props.icaoId}
              hazard={data}
              data={data}
              rawAirSigmet={rawAirSigmet}
            />
          ) : (
            <></>
          )}
        </div>
      </>
    )
  }

  return <>{elements}</>
}

const AirmetTable = (props) => {
  const { currentItem, icaoId, hazard, data, rawAirSigmet } = props
  // convert the object to an array of key-value pairs
  const properties = Object.keys(currentItem.properties)
    .map((key) => [key, currentItem.properties[key]])
    .sort((a, b) => a[0].localeCompare(b[0]))

  return (
    <>
      <div>
        <div className="flex flex-col ">
          <div className="flex w-full text-sm py-4">
            <div className="mr-1 basis-36 grow-0 shrink-0">airSigmentType</div>
            <div className="mr-1">AIRMET</div>
          </div>
          <div className="flex w-full text-sm py-4">
            <div className="mr-1 basis-36 grow-0 shrink-0">icaoId</div>
            <div className="mr-1">{icaoId}</div>
          </div>
          {properties.map(([key, value], index) => {
            // Skip rendering the specified keys
            if (
              key === 'airSigmetType' ||
              key === 'icaoId' ||
              key === 'rawAirSigmet' ||
              key === 'data'
            ) {
              return null
            }
            return (
              <div className="flex w-full text-sm py-4" key={index}>
                <div className="mr-1 basis-36 grow-0 shrink-0">{key}</div>
                <div className="mr-1">{value}</div>
              </div>
            )
          })}
        </div>
        <div className="flex w-full text-sm py-4" title={props.rawAirSigmet}>
          <div className="mr-1 basis-36 grow-0 shrink-0">rawAirSigmet</div>
          <div className="mr-1">{props.rawAirSigmet}</div>
        </div>
      </div>
    </>
  )
}

export default CustomMapMarker
