/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useEffect, useState } from 'react'
import { WMSTileLayer, useMap } from 'react-leaflet'
import L, { Map as leafletMap, WMS } from 'leaflet'
import TWSOpacitySlider from '../api/OpacitySlider'

interface IBaseLayerProps {
  url?: string
  // layers: string
  opacity?: number
  attribution?: string
  map?: leafletMap
}

// this doesn't work, but I'm not giving up hope yet
function addControlPlaceholders(map){
  const corners = map._controlCorners
  const l = 'leaflet-'
  const container = map._controlContainer

  function createCorner(vSide, hSide) {
    const className = l + vSide + ' ' + l + hSide

    corners[vSide + hSide] = L.DomUtil.create('div', className, container)
  }

  createCorner('verticalcenter', 'left')
  createCorner('verticalcenter', 'right')
  createCorner('horizontalcenter', 'top')
  createCorner('horizontalcenter', 'bottom')
}

const SectionalChartComponent = (props: IBaseLayerProps) => {
  const tilesUrl = 'https://charts.v360weather.com/'
  const tileLayerRef = useRef<WMSTileLayer | null>(null)
  const opacitySliderRef = useRef<TWSOpacitySlider | null>(null)
  const hasAddedSlider = useRef(false)
  const defaultOpacity = 0.8
  const [opacityControls, setOpacityControls] = useState<number>(0)
  const [lastUpdated, setLastUpdated] = useState()
  const leafletMap = useMap()
  const [attribution, setAttribution] = useState<string>('')
  const [maxNativeZoom, setMaxNativeZoom] = useState<number>(10)
  const [minNativeZoom, setMinNativeZoom] = useState<number>(6)
  
  useEffect(() => {
    fetch('https://charts.v360weather.com/update_metadata.json?'+Date.now())
    .then(response => response.json())
    .then(json => {
      const getMonthName = (month) => {
        var monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames[month];
      };
      const dateUpdated = new Date(json.last_updated);
      if (json.maxNativeZoom !== undefined)
        setMaxNativeZoom(json.maxNativeZoom)
      if (json.minNativeZoom!== undefined)
        setMinNativeZoom(json.minNativeZoom)
      setAttribution("<a href='https://www.faa.gov/air_traffic/flight_info/aeronav/productcatalog/vfrcharts/sectional/' target='_blank' >FAA Sectional Charts</a> - Last Updated: " + getMonthName(dateUpdated.getMonth()) + " " + dateUpdated.getFullYear());
    })
  
    return () => {
      leafletMap.attributionControl.removeAttribution(attribution);
    }
  });

  useEffect(() => {
    if (tileLayerRef.current && !hasAddedSlider.current) {
      opacitySliderRef.current = new TWSOpacitySlider({
        title: 'Aeronautical Map Opacity',
        tileLayer: tileLayerRef.current,
        position: 'bottomright',
        opacity: defaultOpacity,
      });
      opacitySliderRef.current.addTo(tileLayerRef.current._map)
      hasAddedSlider.current = true
      setOpacityControls(opacityControls + 1)
    }

    return () => {
      if (opacitySliderRef.current) {
        opacitySliderRef.current.remove()
        opacitySliderRef.current = null
        hasAddedSlider.current = false
      }
    }
  }, [props.opacity])

  return (
    <>
      <WMSTileLayer
        attribution={attribution}
        url={tilesUrl+"{z}/{x}/{y}.png"}
        layers="sec"
        opacity={defaultOpacity}
        ref={(ref) => { tileLayerRef.current = ref }}
        minNativeZoom={1}
        maxNativeZoom={maxNativeZoom}
        maxZoom={18}
      />
    </>
  )
}

const SatelliteComponent = (props: IBaseLayerProps) => {
  const tileLayerRef = useRef<WMSTileLayer | null>(null)
  const opacitySlider = new TWSOpacitySlider({
    title: 'Satellite Map Opacity', 
    tileLayer: tileLayerRef.current, 
    position: 'bottomright', 
    opacity: props.opacity
  })

  useEffect(() => {
    opacitySlider.addTo(tileLayerRef.current._map)
    opacitySlider.setTileLayer(tileLayerRef.current)
    return () => {
      opacitySlider.remove()
    }
  }, [])

  return (
    <>
      <WMSTileLayer
        attribution={props.attribution}
        url={props.url}
        opacity={props.opacity}
        maxNativeZoom={16}
        // maxZoom={18}
        ref={(ref) => {
          tileLayerRef.current = ref
        }}
      ></WMSTileLayer>
    </>
  )
}

export { SectionalChartComponent, SatelliteComponent }
