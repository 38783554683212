/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { tempestClientID, TWS_API_URL } from '../environment/apis.config'
import { timeouts } from '../../src/services/RequestTimeouts'

const API_URL = `${TWS_API_URL}/`
export const getSensorData = () => {
  let payload ={
    // "clientId":sessionStorage.getItem('userId')
    "clientId":localStorage.getItem('userId')
  }
  const controller = new AbortController()

  return new Promise((resolve, reject) => {

    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('PIREP request timed out. Please try again.'))
    }, timeouts.sensorDetails)

    fetch(API_URL+"client/getSensorData", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(payload),
      signal: controller.signal,
    })
      .then(async (res) => {
        clearTimeout(timeoutId)
        const data = await res.json()
        resolve(data)
      })
      .catch((err) => {
        console.log(err)
      })
  })
}

export const getTempestToken = () => {
  var url = "https://tempestwx.com/authorize.html?client_id=" + tempestClientID +"&response_type=code&redirect_uri=" + window.location.href
  window.location.href = url;
}

export const updateSensorData = (sensorData) => {
  const payload = {
    // "clientId":sessionStorage.getItem('userId'),
    "clientId":localStorage.getItem('userId'),
    "sensorData":sensorData
  }
  const controller = new AbortController()

  const bodystr = JSON.stringify(payload)
  return new Promise((resolve, reject) => {

    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('PIREP request timed out. Please try again.'))
    }, timeouts.sensorDetails)

    fetch(API_URL+"client/updateSensorData", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: bodystr,
      signal: controller.signal,
    }).then((res) => {
      clearTimeout(timeoutId)
      resolve(true)
    })
  })
}
