export enum WetDogWeatherLayersEnum {
  MRMS = 0,
  RADAR = 1,
  CLOUDCEILING = 2,
  CLOUDCOVER = 3,
  TEMPERATURE = 4,
  WINDUV = 5,
  WIND80M = 6,
  WINDGUST = 7,
}
