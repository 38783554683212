import L from 'leaflet'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import '../../utils/KMZLayer/KMZLayer.js'

const AeroxLayer = () => {
    const map = useMap()
  
    useEffect(() => {
        var kmz = L.kmzLayer().addTo(map);
        
        kmz.load('../../../public/AeroX_Ops.kmz');
        kmz.on('load', function(e) {
            e.layer.addTo(map)
        });
        return () => {
            map.removeLayer(kmz)
        }
    }, [map])
  
    return null
  }
  
  export default AeroxLayer