import { Accordion } from 'flowbite-react';
import MainNavMenu from '../../menus/MainNavMenu/MainNavMenu';
import questions from './questionaire';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import type { CustomFlowbiteTheme } from "flowbite-react";
import { Flowbite } from 'flowbite-react';

export const customTheme: CustomFlowbiteTheme = {
    accordion:{
        root: {
            base: 'divide-y divide-gray-500 border-gray-700 dark:divide-gray-700 dark:border-gray-700',
            flush: {
                off: 'rounded-lg border',
                on: 'border-b',
            },
        },
        content: {
            base: 'py-5 px-5 last:rounded-b-lg dark:bg-gray-900 first:rounded-t-lg',
        },
        title: {
            arrow: {
                base: 'h-6 w-6 shrink-0',
                open: {
                    off: '',
                    on: 'rotate-180',
                },
            },
            base: 'flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg py-5 px-5 text-lg text-left font-semibold text-gray-900 ',
            flush: {
                off: 'focus:ring-4 focus:ring-gray-200 dark:hover:bg-gray-800 dark:focus:ring-gray-800',
                on: 'bg-transparent dark:bg-transparent',
            },
            heading: '',
            open: {
                off: '',
                on: 'text-white bg-black dark:bg-gray-800 dark:text-white',
            },
        },
    }
};


function FAQPage() {
    return (
        <div className="max-h-screen flex flex-col w-full  overflow-y-scroll">{/*flex flex-col w-full bg-white items-center*/}
            <MainNavMenu />
            <h1 className="text-2xl font-bold text-black mt-4 ml-12">Frequently Asked Questions</h1>
            <div className='grid h-auto  overflow-y-auto mt-4 mb-8 border-1 border-gray-500 rounded-xl justify-items-center'>
                <div className="w-[1028px]  justify-items-stretch">
                    <Flowbite  theme={{ theme: customTheme }}>
                        <Accordion collapseAll>
                            {questions.map((q) => (
                                <Accordion.Panel className=''>
                                    <Accordion.Title>{q.header}</Accordion.Title>
                                    <Accordion.Content>
                                            {q.subheader ? 
                                                <Accordion collapseAll>
                                                    {q.subheader.map((sub_header) => (
                                                        <Accordion.Panel>
                                                            <Accordion.Title>
                                                                {sub_header.header}
                                                            </Accordion.Title>
                                                            <Accordion.Content>
                                                            <Accordion collapseAll>
                                                                {sub_header.subquestions.map((subq) => (
                                                                    <Accordion.Panel>
                                                                        <Accordion.Title>{subq.title}</Accordion.Title>
                                                                        <Accordion.Content>
                                                                            <p className="mb-2 text-black">
                                                                                {ReactHtmlParser(subq.answer)}
                                                                            </p>                   
                                                                        </Accordion.Content>
                                                                    </Accordion.Panel>
                                                                ))}
                                                            </Accordion>
                                                            </Accordion.Content>
                                                        </Accordion.Panel>
                                                    
                                                    ))}
                                                    
                                                </Accordion>
                                                : 
                                                <Accordion collapseAll>
                                                    {q.subquestions.map((subq) => (
                                                        <Accordion.Panel>
                                                            <Accordion.Title>{subq.title}</Accordion.Title>
                                                            <Accordion.Content>
                                                                <p className="mb-2 text-black">
                                                                    {ReactHtmlParser(subq.answer)}
                                                                </p>                   
                                                            </Accordion.Content>
                                                        </Accordion.Panel>
                                                    ))}
                                                </Accordion>
                                            }                                    
                                    </Accordion.Content>
                                </Accordion.Panel>
                            ))}
                        </Accordion>  
                    </Flowbite>
                                  
                </div>
            </div>
            
        </div>
    );
}

export default FAQPage;

