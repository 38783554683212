import { useState } from "react";
import { Popup, useMapEvents } from "react-leaflet";
import closeIcon from "../../assets/close.svg"


const ContactPopUp = ({ onClose }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-lg" style={{ maxWidth: '600px', width: '100%' }}>
          {/* Header Section */}
          <div className="bg-black text-white flex justify-between items-center rounded-t-lg w-full">
            <h2 className="m-0 p-4">Contact Us</h2>
            <button onClick={onClose} className="m-0 p-4">
              <img
                src={closeIcon}
                alt="Close"
                className="h-6 w-6" // Ensure this height and width are appropriate
              />
            </button>
          </div>
          {/* Content Section */}
          <p className="p-4">To obtain assistance or to report a problem with V360, please email us at <a href="mailto:support@truweathersolutions.com" className="text-blue-500 hover:text-blue-700">
          support@truweathersolutions.com</a></p>
        </div>
      </div>
    );
  };
  
  
  

export const ContactUsButton = () => {
    const [showPopup, setShowPopup] = useState(false);
  
    return (
        // <div style="z-index: 1;">
        <div>
        <button
          className="absolute bottom-8 right-12 z-9999 h-12 w-12 rounded-full bg-blue-500 text-white flex items-center justify-center text-lg cursor-pointer focus:outline-none"
          onClick={() => setShowPopup(true)}
          aria-label="Contact us"
        >
          !
        </button>
        {showPopup && (
          <ContactPopUp onClose={() => setShowPopup(false)} />
        )}
      </div>
    );
  };