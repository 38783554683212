/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { updateCustomUnits } from '../Components/Users/UserSlice'
import { TWS_API_URL } from '../environment/apis.config'
import { timeouts } from '../services/RequestTimeouts'
import store from '../store'

const API_URL = `${TWS_API_URL}/customUserProfile`
export const getCustomProfile = (flag: boolean) => {
  const controller = new AbortController()

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('Request Timeout'))
    }, timeouts.customProfile)

    fetch(API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      signal: controller.signal,
    })
      .then(async (res) => {
        if (res.status === 200) {
          const data = await res.json()
          const units = {...data.customProfile.units}
          // store.dispatch(updateCustomUnits(units))
          resolve(data)
        } else {
            postCustomProfile()
            resolve(getCustomProfile(false))
          }
        clearTimeout(timeoutId)
        if(!res.ok) {
          throw new Error('Network request timeout getting Custom Profile')
        }
        return res.json()
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          reject(new Error('Network request timeout getting custom profile'))
        } else {
          reject(err)
        }
      })
  })
}
export const postCustomProfile = () => {
  const controller = new AbortController()

  const customProfile = {
    timeRepresentation: 'localtime-region/city',
    unitOutput: 'concatenated',
    units: 'F',
  }
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('Request Timeout'))
    }, timeouts.customProfile)

    fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ customProfile }),
      signal: controller.signal,
    })
    .then((res) => {
      clearTimeout(timeoutId)
      if (!res.ok) {
        throw new Error('Network timeout saving custom profile')
      }
      return res.json()
    })
    .catch((err) => {
      if (err.name === 'AbortError') {
        reject(new Error('Network timeout saving custom profile'))
      }
    })
  })
}

export const putCustomProfile = (unit) => {
  const controller = new AbortController()

   const customProfile = {
    timeRepresentation: 'localtime-region/city',
    unitOutput: 'concatenated',
    units: unit,
  }
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('Request Timeout'))
    }, timeouts.customProfile)

    fetch(API_URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ customProfile }),
      signal: controller.signal,
    })
    .then((res) => {
      clearTimeout(timeoutId)
      if (!res.ok) {
        throw new Error('Network error updating custom profile')
      }
      return (true)
    })
    .then(data => {
      resolve(data)
    })
    .catch((err) => {
      if (err.name === 'AbortError') {
        reject(new Error('Network error updating custom profile'))
      } else {
        reject(err)
      }
    })
  })
}
