import { Link, useLocation } from 'react-router-dom';

const MainNavMenu = () => {
  const location = useLocation();

  const breadcrumbMap: { [key: string]: string } = {
    '/map': 'Map',
    '/customthresholds': 'Custom Thresholds',
    '/userprofile': 'Your Profile',
    '/sensordetails': 'Weather Sensor Details',
    '/faq': 'Frequently Asked Questions'
  };

  const currentBreadcrumbText = breadcrumbMap[location.pathname] || 'Unknown Page';

  const breadcrumbItems = [
    { text: 'Map', link: '/map' },
    { text: currentBreadcrumbText, link: location.pathname },
  ];

  return (
    <nav className='mt-6 ml-12'>
      <ul className="flex space-x-2">
        {breadcrumbItems.map((item, index) => (
          <li key={index}>
            {index > 0 && <span className="text-gray-500">{' > '}</span>}
            <Link to={item.link} className="text-black hover:underline">
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MainNavMenu;
