import { toPng } from 'html-to-image'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch'
import { exportTableToExcel } from '../../utils/exportTableToExcel'
import PdfGenerator from '../../utils/PdfGenerator'


function appendFormattedDateToString(baseString) {
  const date = new Date();
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  
  const formattedDate = `-${year}${month}${day}-${hours}${minutes}`;
  
  return baseString + formattedDate;
}

const handleExcelExport = (e) => {
  e.stopPropagation();
  const missioncast = document.getElementById('missionCast')
  if (!missioncast) {
    return
  }
  
  // Store the original size
  const originalWidth = missioncast.style.width
  const originalHeight = missioncast.style.height
  const originalTransform = missioncast.style.transform

  // Increase size - adjust scale factor as needed
  const scaleFactor = 2
  missioncast.style.transform = `scale(${scaleFactor})`
  missioncast.style.transformOrigin = 'top left'

  exportTableToExcel(missioncast, appendFormattedDateToString("MissionCAST") + ".xlsx")

  // Reset to original size
  missioncast.style.width = originalWidth
  missioncast.style.height = originalHeight
  missioncast.style.transform = originalTransform
}


const Controls = ({ data, zoomIn, zoomOut, resetTransform, showExportButtons, setShowExportButtons }) => {
  
const handlePngExport = (e) => {
  e.stopPropagation();
  const missioncast = document.getElementById('missionCast')

  if (!missioncast) {
    return
  }
  // Store the original size
  const originalWidth = missioncast.style.width
  const originalHeight = missioncast.style.height
  const originalTransform = missioncast.style.transform
  // Increase size - adjust scale factor as needed
  const scaleFactor = 2
  missioncast.style.transform = `scale(${scaleFactor})`
  missioncast.style.transformOrigin = 'top left'

  toPng(missioncast, {
    cacheBust: true,
    width: missioncast.offsetWidth * scaleFactor,
    height: missioncast.offsetHeight * scaleFactor,
  })
    .then((dataUrl) => {
      // Reset to original size
      missioncast.style.width = originalWidth
      missioncast.style.height = originalHeight
      missioncast.style.transform = originalTransform

      // Create link and download
      const link = document.createElement('a')
      const fileName = appendFormattedDateToString('MissionCAST')+'.png'
      link.download = fileName
      link.href = dataUrl
      link.click()
    })
    .catch((err) => {
      console.log(err)
      // Reset to original size in case of error as well
      missioncast.style.width = originalWidth
      missioncast.style.height = originalHeight
      missioncast.style.transform = originalTransform
    })
}

return (
  <div className="flex justify-end items-end space-x-2 absolute top-0 right-0 m-2">
    {showExportButtons ? (
      <>
        <button
          id="exportExcelButton"
          className="bg-gray-300 mt-5 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          onClick={handleExcelExport}
        >
          <span>Export Excel</span>
        </button>
        <button
          id="exportPngButton"
          className="bg-gray-300 mt-5 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          onClick={handlePngExport}
        >
          <span>Export PNG</span>
        </button>
        <PdfGenerator element={data} fileName={appendFormattedDateToString("MissionCAST")+".pdf"}/>
      </>
    ) : (
      <button
        id="exportButton"
        className="bg-gray-300 mt-5 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        onClick={(e) => { e.stopPropagation(); setShowExportButtons(true); }}
      >
        <svg
          className="w-4 h-4 mr-2 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 16 18"
        >
          <path
            stroke="currentColor"
            d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
          />
        </svg>
        <span>Export</span>
      </button>
    )}
    <button
      className="rounded-full 
      bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
      onClick={() => zoomIn()}
      title="Zoom In View"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-3 h-3"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"
        />
      </svg>
    </button>
    <button
      className="rounded-full 
      bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
      onClick={() => zoomOut()}
      title="Zoom Out View"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-3 h-3"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6"
        />
      </svg>
    </button>
    <button
      className="rounded-full 
      bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
      onClick={() => resetTransform()}
      title="Reset View"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-3 h-3"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
    </button>
  </div>
)
}

const ZoomableContent = ({ content, children }) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)
  const [showExportButtons, setShowExportButtons] = useState(false);

  return (
    <TransformWrapper centerOnInit initialScale={1} ref={transformComponentRef}>
      {(utils) => (
        <React.Fragment>
          <TransformComponent>{content}</TransformComponent>
          <Controls {...utils} content={content} showExportButtons={showExportButtons} setShowExportButtons={setShowExportButtons} />
        </React.Fragment>
      )}
    </TransformWrapper>
  )
}

export const ZoomablePannablePopupContent = ({ content }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null); // Ref for the content inside the container
  const [scale, setScale] = useState(1);
  const [showExportButtons, setShowExportButtons] = useState(false);

  const zoomIn = () => {
    let newScale = scale + 0.1;
    newScale = Math.max(1, newScale); // Prevent scaling smaller than original size
    setScale(newScale);
  };

  const zoomOut = () => {
    let newScale = scale - 0.1;
    newScale = Math.max(1, newScale); // Prevent scaling smaller than original size
    setScale(newScale);
  };

  const resetTransform = () => {
    setScale(1);
  };

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current; // The actual content to be zoomed

    const onWheel = e => {
      e.preventDefault(); // Prevents the whole page from zooming
      const wheelDelta = e.deltaY;

      if (wheelDelta < 0) {
        zoomIn()
      } else if (wheelDelta > 0) {
        zoomOut()
      }
      
      content.style.transform = `scale(${scale})`; // Apply scale to content
    };

    container.addEventListener('wheel', onWheel);

    return () => {
      container.removeEventListener('wheel', onWheel);
    };
  }, [scale,containerRef]);

  // Container style to allow for overflow (scrolling)
  const containerStyle = {
    cursor: 'grab',
    overflow: 'auto',
    width: '100%',
    height: '100%'
  };

  // Content style to apply the zoom (scale transformation)
  const contentStyle = {
    transformOrigin: 'top left',
    transform: `scale(${scale})`
    
  };

  return (
    
    <div ref={containerRef} style={containerStyle}>
      <div ref={contentRef} style={contentStyle}>
        {content}
      </div>
      <Controls content={content} data={contentRef.current} zoomIn={zoomIn} zoomOut={zoomOut} resetTransform={resetTransform} showExportButtons={showExportButtons} setShowExportButtons={setShowExportButtons} />
    </div>
    
  
  );
};

export default ZoomableContent
