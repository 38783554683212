import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const exportTableToExcel = async (containerElement: HTMLElement, filename: string = 'WeatherForecast') => {
    if (!containerElement) {
        console.error('Invalid container element');
        return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const rgbToHex = (r: number, g: number, b: number) => {
        const hex = (x: number) => x.toString(16).padStart(2, '0');
        return `FF${hex(r)}${hex(g)}${hex(b)}`;
    };

    const getBackgroundColor = (element: HTMLElement) => {
        const style = window.getComputedStyle(element);
        const bgColor = style.backgroundColor;
        const match = bgColor.match(/rgba?\((\d+), (\d+), (\d+)(?:, [\d.]+)?\)/);
        if (match) {
            const r = parseInt(match[1]);
            const g = parseInt(match[2]);
            const b = parseInt(match[3]);
            // Check if the color is black (0, 0, 0) or transparent
            if (r === 0 && g === 0 && b === 0) {
                return 'FFFFFFFF'; // Default to white
            }
            return rgbToHex(r, g, b);
        }
        return 'FFFFFFFF'; // Default to white if no match
    };

    const extractTableData = (tableElement: HTMLTableElement, startRow: number) => {
        const thead = tableElement.querySelector('thead');
        const tbody = tableElement.querySelector('tbody');

        if (thead) {
            const headerRow: string[] = [];
            thead.querySelectorAll('th').forEach((header) => {
                headerRow.push(header.textContent || '');
            });
            worksheet.addRow(headerRow);
        }

        if (tbody) {
            tbody.querySelectorAll('tr').forEach((row) => {
                const rowData: string[] = [];
                row.querySelectorAll('th, td').forEach((cell) => {
                    rowData.push(cell.textContent || '');
                });
                worksheet.addRow(rowData);
            });
        }

        const rows = tableElement.querySelectorAll('tr');
        rows.forEach((row, rIndex) => {
            row.querySelectorAll('th, td').forEach((cell, cIndex) => {
                const cellAddress = worksheet.getRow(startRow + rIndex + 1).getCell(cIndex + 1);
                const bgColor = getBackgroundColor(cell as HTMLElement);

                cellAddress.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: bgColor }
                };
            });
        });
    };

    const addMetadataRow = (content: string) => {
        const row = worksheet.addRow([content]);
        worksheet.mergeCells(`A${row.number}:Z${row.number}`);
        row.font = { bold: true };
    };

    // Extract date and coordinates
    let date = '';
    let coordinates = '';

    const metadataElements = containerElement.querySelectorAll('div[style*="display:table-cell"], div[style*="display:block"]');
    metadataElements.forEach((element) => {
        const textContent = element.textContent || '';
        if (textContent.startsWith('Date -')) {
            date = textContent.trim();
        } else if (textContent.startsWith('Coordinates:')) {
            coordinates = textContent.trim();
        }
    });

    // Add metadata and table data
    if (date) addMetadataRow(date);
    if (coordinates) addMetadataRow(coordinates);
    addMetadataRow('Short Range Forecast (0-24 hours)');

    const tables = containerElement.querySelectorAll('table');
    if (tables[0]) {
        extractTableData(tables[0] as HTMLTableElement, worksheet.rowCount);
    }

    addMetadataRow('Extended Range Forecast (25-144 hours)');
    if (tables[1]) {
        extractTableData(tables[1] as HTMLTableElement, worksheet.rowCount);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${filename}.xlsx`);
};
