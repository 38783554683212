interface WeatherData {
  [key: string]: {
    value: any
    units?: string
  }
}

interface TAFParsedData {
  latitude: number
  longitude: number
  properties?: { [key: string]: any }
}
export function createPopUpKeyValuePair(
  data: WeatherData
): { key: string; value: any }[] {
  return Object.entries(data).map(([key, value]) => ({
    key,
    value: value.value,
  }))
}

export function TAFAPIParser(apiresponse: TAFData) {
  const parsedArray: TAFParsedData[] = []
  let unit: JSON
  apiresponse?.features?.forEach((feature) => {
    if (!feature.geometry) {
      unit = JSON.parse(JSON.stringify(feature))
    }
  })
  apiresponse?.features?.forEach((feature) => {
    let newObject: TAFParsedData = {
      latitude: 0,
      longitude: 0,
    }
    if (feature.geometry) {
      feature.properties.Unit = unit

      if (feature.properties['Raw TAF']) {
        let rawTAF = feature.properties['Raw TAF']
        rawTAF = rawTAF.split('FM').join('\nFM')
        feature.properties['Raw TAF'] = rawTAF
      }
      newObject = {
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
        properties: feature.properties,
      }

      parsedArray.push(newObject)
    }
  })
  return parsedArray
}
