import moment from 'moment' // date formatter
import './mediacontrols.css'
import { useMap } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { WetDogWeatherLayersEnum } from '../../../interfaces/WetDogWeatherLayers'
import { useEffect, useState } from 'react'
//
// Handles time scrubber at the bottom, play functionality, and displaying date
//

function MediaControls({
  curTime,
  setCurTime,
  isPlaying,
  timeRange,
  setIsPlaying,
}) {
  const map = useMap()
  const curLayer = useSelector((state) => state.WetDogWeatherProducts.curLayer)
  const [minRange, setMinRange] = useState(0)
  const [maxRange, setMaxRange] = useState(10000)

  // let minRange = 0
  // Date we're showing
  const displayDate = moment(Number(new Date(curTime * 1000))).format(
    'dddd M-D-yy | hh:mm a'
  )

  useEffect(() => {
      setMaxRange(10000)
      setMinRange(0)
  }, [curLayer.layer])

  const range = Number(timeRange[1]) - Number(timeRange[0])
  const scrubberValue =
    ((Number(curTime) - Number(timeRange[0])) / range) * 10000

  if (
    curLayer.layer === WetDogWeatherLayersEnum.RADAR &&
    scrubberValue < minRange && scrubberValue > -2000
  ) {
    setMinRange(scrubberValue)
  }

  const handleChange = (e) => {
    setIsPlaying(false)
    setCurTime(
      (e.target.value / 10000) * (timeRange[1] - timeRange[0]) + timeRange[0]
    )
  }

  function onPlayClick() {
    setIsPlaying(!isPlaying)
  }

  // Setting the scrubber value when this component re-renders.
  // Making sure the icon is correct.
  // This logic is necessary incase the MediaControls need to de-render and re-render because of fullscreen.

  const darkMode = useSelector((state) => state.mapState.darkMode)
  const icon = !isPlaying ? (
    <svg
      className={`w-8 h-8 ${!darkMode ? 'text-black' : 'text-white'}`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 14 16"
    >
      <path d="M0 .984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L1.506.139A1 1 0 0 0 0 .984Z" />
    </svg>
  ) : (
    <svg
      className={`w-8 h-8 ${!darkMode ? 'text-black' : 'text-white'}`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 12 16"
    >
      <path d="M3 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm7 0H9a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z" />
    </svg>
  )

  //TODO: create a function to calculate hourly intervals between time ranges
  //TODO: create datalists for the above timestamps and display on the timeline
  // Called when the user clicks the play or pause button
  // TODO: We don't have to make this a toggle.  We could make it deterministic

  return (
    <>
      <div
        className="flex flex-col rounded-md h-full "
        onMouseOver={() => map.dragging.disable()}
        onMouseOut={() => map.dragging.enable()}
      >
        <div className="flex flex-row h-full rounded-md justify-center items-center text-center relative">
          <button
            className="mr-1 w-8 h-8 rounded-full text-center m-2"
            onClick={() => onPlayClick()}
          >
            {icon}
          </button>
          <div
            className={
              'relative w-4/5 h-1/2 flex flex-row justify-center items-center'
            }
          >
            <div
              className={`range-value w-auto p-4 m-2 h-12 -translate-y-10 flex flex-row
            justify-center items-center text-xl text-center font-semibold tracking-wide
            leading-6 rounded-lg ${!darkMode ? 'text-black' : 'text-white'}`}
            >
              {displayDate}
            </div>
            <input
              type="range"
              min={minRange}
              max={maxRange}
              value={scrubberValue}
              id="scrubber-range"
              onChange={(e) => handleChange(e)}
              step="100"
              className="w-full h-1 bg-blue-500 rounded-lg cursor-pointer"
              list="markers"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaControls
