import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"

interface IMessage {
    message: string
    messageType: string
    messageCode?: number
    sticky?: boolean
    identifier: string
}

interface StatusQueueState {
    messages: Array<IMessage>
    showApiLoading: boolean
    lastPoppedMessage: IMessage | null
}

const initialState: StatusQueueState = {
    messages: [],
    showApiLoading: false,
    lastPoppedMessage: null,
}

export const pushMessage = createAsyncThunk(
    'statusQueue/pushMessage',
    async (messageDetails, { dispatch }) => {
        const { message, messageType, messageCode, sticky, identifier, duration } = messageDetails;

        // Push the message to the queue
        dispatch(addMessageToQueue({ message, messageType, messageCode, sticky, identifier, duration }));
        // If the message is not sticky, set a timer to remove it after 7 sec
        if (!sticky) {
            setTimeout(() => {
                dispatch(removeMessage(identifier));
            }, 7000);
        }
    }
);
    
export const StatusQueueSlice = createSlice({
    name: 'statusQueue',
    initialState,
    reducers: {
        showApiLoading: (state) => {
            state.showApiLoading = true
        },
        hideApiLoading: (state) => {
            state.showApiLoading = false
        },
        addMessageToQueue: (state, action: PayloadAction<IMessage>) => {
            state.messages.push(action.payload);
        },
        popMessage: (state) => {
            const queue = [...state.messages]
            const poppedMessage = queue.pop()
            state.messages = queue
            state.lastPoppedMessage = poppedMessage !== undefined ? poppedMessage : null
        },
        removeMessage: (state, action) => {
            const queue = state.messages.filter(item => item.identifier !== action.payload)
            const removedItem = state.messages.find(item => item.identifier === action.payload)
            if (removedItem !== undefined && removedItem !== null){
                state.lastPoppedMessage = removedItem
            }
            state.messages = queue
        }
    },
    extraReducers: (builder) => {
    }
})

export const { popMessage, removeMessage, addMessageToQueue, showApiLoading, hideApiLoading } = StatusQueueSlice.actions
export default StatusQueueSlice.reducer